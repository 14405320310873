import React, { useState } from 'react';
import ExportBtn from '../ExportBtn/ExportBtn';
import { getUserFeedbacks } from '../../../../../../redux/api';
import { exportJsonToExcel } from '../../../../../../../../../util/utils';

export default function ExportFeedback() {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    const fileName = 'All_User_Feedback.xlsx';
    const response = await getUserFeedbacks();
    if (response && response.data.length > 0) {
      exportJsonToExcel(fileName, response.data);
    }
    setLoading(false);
  };

  return (
    <ExportBtn
      size="small"
      classes="file-download"
      id="exportFeedbackBtn"
      variant="filled"
      text="User Feedback"
      title="User Feedback"
      onClick={handleClick}
      loading={loading}
    />
  );
}
