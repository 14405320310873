import ExcelJS from 'exceljs';

//@TO-DO: Replace with exportJsonToExcel because this is not generic
export const exportSurvey = async (fileName, data) => {
  const alphaStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');
  const columns = [
    { header: 'ID', key: 'id', width: 36, fgColor: { argb: 'FFFF00' } },
    { header: 'Start date', key: 'startDate', width: 20 },
    { header: 'End date', key: 'endDate', width: 20 },
    { header: 'Email', key: 'email', width: 12 },
    { header: 'Name', key: 'name', width: 8 },
    { header: 'Language', key: 'language', width: 22 },
    { header: 'Competence center Id', key: 'ccCode', width: 20 },
    { header: 'Competence center name', key: 'ccName', width: 45 },
    { header: 'Additional info', key: 'ccPTName', width: 22 },
    { header: 'BL', key: 'blCode', width: 20 },
    { header: 'GBU', key: 'gbuCode', width: 8 },
    { header: 'Country', key: 'country', width: 35 },
    { header: 'Mood Value', key: 'moodSatisfaction', width: 14 },
    { header: 'Confidence to deliver value', key: 'confindenceToDeliverSatisfaction', width: 27 },
    {
      header: 'Confidence to deliver comment',
      key: 'confindenceToDeliverComment',
      width: 100,
    },
    {
      header: 'Mood Comment',
      key: 'moodComment',
      width: 100,
    },
    { header: 'Project id', key: 'pId', width: 32 },
    { header: 'Project name', key: 'pName', width: 160 },
    // { header: "Corrected Project Name", key: "correctedProjectName", width: 160 }, // TODO: Add this column after Laurentiu finnish the BO api
  ];

  const styleColumns = () => {
    worksheet.columns.forEach((column, i) => {
      const cellName = `${alphaStr[i]}1`;
      const cell = worksheet.getCell(cellName);
      cell.font = {
        color: { argb: 'DEEAF5' },
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '5B9BD5' },
      };
      // cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.border = {
        top: { style: 'double', color: { argb: 'FFFFFF' } },
        left: { style: 'double', color: { argb: 'FFFFFF' } },
        bottom: { style: 'double', color: { argb: 'FFFFFF' } },
        right: { style: 'double', color: { argb: 'FFFFFF' } },
      };
    });
  };

  const addCells = data => {
    data.forEach((row, i) => {
      if (!row.ccCode) {
        row.ccCode = '';
      }
      if (!row.name) {
        row.name = '';
      }
      if (!row.language) {
        row.language = '';
      }
      if (!row.moodComment) {
        row.moodComment = '';
      }
      if (!row.pName) {
        row.pName = '';
      }
      if (!row.pId) {
        row.pId = '';
      }
      if (!row.endDate) {
        row.endDate = '';
      }
      if (!row.ccCode) {
        row.ccCode = '';
      }
      if (!row.ccPTName) {
        row.ccPTName = '';
      }
      if (!row.ccName) {
        row.ccName = '';
      }
      if (!row.confindenceToDeliverComment) {
        row.confindenceToDeliverComment = '';
      }
      if (!row.moodComment) {
        row.moodComment = '';
      }

      if (row.ccName !== 'Off-GBU/FR(TCE-TDF ENG.IO)') {
        row.ccPTName = '';
      }

      const xRow = worksheet.addRow(row);
      xRow.eachCell((cell, colNumber) => {
        if (['moodSatisfaction', 'confindenceToDeliverSatisfaction'].includes(cell._column._key)) {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }
      });
    });
  };

  const addColumns = () => {
    worksheet.columns = columns;
    styleColumns();
  };

  const downloadXcel = async (data, fileName) => {
    const debugTimeLabel = 'xcel generation time';
    console.time(debugTimeLabel);

    addColumns();
    addCells(data);

    worksheet.autoFilter = {
      from: 'A1',
      to: 'R1',
    };

    setTimeout(async () => {
      // Move execution to a separate process to not freeze the page
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      console.timeEnd(debugTimeLabel);
    }, 100);
  };

  return downloadXcel(data, fileName);
};
