import React, { useState } from 'react';
import ExportBtn from '../ExportBtn/ExportBtn';
import EmwHotTable from '../../../../../../../../components/EmwHotTable/EmwHotTable';
import { ccExcelFormColumns } from './CCExcelFormColumns';
import { getAllCcsApi, saveCcDetailsApi } from '../../../../../../../organization/redux/api';
import { ccExcelFormExport } from './CCExcelFormExport';

/**
 * @todo: Refactor this component to implement <AdminToolsEditBtn />
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function ExportCcList() {
  const [loading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = async () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ExportBtn
        size="small"
        classes="file-download"
        id="exportBtn"
        variant="filled"
        text="Engineering CC List"
        title="Engineering CC List"
        onClick={handleClick}
        loading={loading}
      />
      {isOpen && (
        <EmwHotTable
          id="ccHotTable"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          columns={ccExcelFormColumns}
          getApi={getAllCcsApi}
          saveApi={saveCcDetailsApi}
          ccExcelFormExport={ccExcelFormExport}
          isCcForm={true}
          hasReadMeFile={false}
        />
      )}
    </>
  );
}
