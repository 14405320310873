import React, { useState } from 'react';
import { computeURL, fetchOptions } from '../../../../../../../../../util/fetch';
import { exportData } from '../../../../../../../../utils/exportUtils';
import ExportBtn from '../ExportBtn/ExportBtn';

export default function ExportEJFHC() {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    const fileName = 'All_Gbu_engineers.xlsx';
    const url = computeURL('employee', 'exportAllGbuJfEngineers');
    const options = fetchOptions({
      method: 'put',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
        'Accept-Encoding': 'gzip, deflate, br',
      },
    });

    await exportData(url, options, fileName);

    setLoading(false);
  };

  return (
    <ExportBtn
      size="small"
      classes="file-download"
      id="exportEJF-HCBtn"
      variant="filled"
      text="Organization EJF-HC"
      title="Engineering Job Family Head Count"
      onClick={handleClick}
      loading={loading}
    />
  );
}
